import React, { createContext, useState, useEffect } from 'react';
import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOPIFY_SHOP_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN,
});

const defaultValues = {
  isSearchOpen: false,
  toggleSearchOpen: () => {},

  wishlist: [],
  isWishlistOpen: false,
  toggleWishlistOpen: () => {},
  toggleProductInWishlist: () => {},
  addProductInWishlistToCheckout: () => {},
  addProductsInWishlistToCheckout: () => {},

  isCartOpen: false,
  toggleCartOpen: () => {},
  client,
  checkout: {
    lineItems: [],
    webUrl: '',
  },
  addProductsToCheckout: () => {},
  updateProductInCheckout: () => {},
  removeProductFromCheckout: () => {},
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isSearchOpen, setSearchOpen] = useState(false);

  const [wishlist, setWishlist] = useState(defaultValues.wishlist);
  const [isWishlistOpen, setWishlistOpen] = useState(false);

  const [isCartOpen, setCartOpen] = useState(false);
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const isBrowser = typeof window !== 'undefined';
  const localStorageWishlistItemsKey = `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:wishlist`;
  const localStorageCheckoutIdKey = `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:checkout-id`;

  useEffect(() => {
    initialiseWishlist();
    initialiseCheckout();
  }, []);

  const toggleSearchOpen = () => setSearchOpen(!isSearchOpen);

  const initialiseWishlist = () => {
    try {
      const savedWishlist = isBrowser
        ? JSON.parse(localStorage.getItem(localStorageWishlistItemsKey))
        : null;

      if (savedWishlist) {
        setWishlist(savedWishlist);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleWishlistOpen = () => setWishlistOpen(!isWishlistOpen);

  const updateWishlist = updatedWishlist => {
    setWishlist(updatedWishlist);
    localStorage.setItem(
      localStorageWishlistItemsKey,
      JSON.stringify(updatedWishlist)
    );
  };

  const toggleProductInWishlist = (shopifyId, title, handle, variant) => {
    const isDuplicate = wishlist.find(
      item => item.variant.shopifyId === variant.shopifyId
    );
    let updatedWishlist = [];

    if (isDuplicate) {
      updatedWishlist = wishlist.filter(
        item => item.variant.shopifyId !== variant.shopifyId
      );
    } else {
      updatedWishlist = [...wishlist, { shopifyId, title, handle, variant }];
    }

    updateWishlist(updatedWishlist);
  };

  const addProductInWishlistToCheckout = async shopifyId => {
    const lineItems = [
      {
        variantId: shopifyId,
        quantity: 1,
      },
    ];
    const updatedWishlist = wishlist.filter(
      item => item.variant.shopifyId !== shopifyId
    );
    await addProductsToCheckout(lineItems);
    updateWishlist(updatedWishlist);
    toggleWishlistOpen();
    toggleCartOpen();
  };

  const addProductsInWishlistToCheckout = async () => {
    const lineItems = wishlist.map(({ variant: { shopifyId } }) => ({
      variantId: shopifyId,
      quantity: 1,
    }));
    await addProductsToCheckout(lineItems);
    updateWishlist(defaultValues.wishlist);
    toggleWishlistOpen();
    toggleCartOpen();
  };

  const toggleCartOpen = () => setCartOpen(!isCartOpen);

  const initialiseCheckout = async () => {
    try {
      const currentCheckoutId = isBrowser
        ? localStorage.getItem(localStorageCheckoutIdKey)
        : null;
      let newCheckout;

      if (currentCheckoutId) {
        newCheckout = await client.checkout.fetch(currentCheckoutId);
        if (newCheckout.completedAt) {
          newCheckout = await getNewCheckout();
        }
      } else {
        newCheckout = await getNewCheckout();
      }

      setCheckout(newCheckout);
    } catch (e) {
      console.error(e);
      const newCheckout = await getNewCheckout();
      setCheckout(newCheckout);
    }
  };

  const getNewCheckout = async () => {
    try {
      const newCheckout = await client.checkout.create();

      if (isBrowser) {
        localStorage.setItem(localStorageCheckoutIdKey, newCheckout.id);
      }

      return newCheckout;
    } catch (e) {
      console.error(e);
    }
  };

  const addProductsToCheckout = async lineItems => {
    try {
      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );

      setCheckout(newCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  const updateProductInCheckout = async (lineItemId, quantity) => {
    try {
      const newCheckout = await client.checkout.updateLineItems(checkout.id, [
        {
          id: lineItemId,
          quantity: quantity,
        },
      ]);

      setCheckout(newCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  const removeProductFromCheckout = async lineItemId => {
    try {
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ]);

      setCheckout(newCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        isSearchOpen,
        toggleSearchOpen,

        wishlist,
        isWishlistOpen,
        toggleWishlistOpen,
        toggleProductInWishlist,
        addProductInWishlistToCheckout,
        addProductsInWishlistToCheckout,

        isCartOpen,
        toggleCartOpen,
        checkout,
        addProductsToCheckout,
        updateProductInCheckout,
        removeProductFromCheckout,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
