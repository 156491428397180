const React = require('react');
const { StoreProvider } = require('./src/context/StoreContext');

exports.wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

exports.onInitialClientRender = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    typeof drift === 'object' &&
    window.driftAppId
  ) {
    setTimeout(() => {
      window.drift.SNIPPET_VERSION = '0.3.1';
      window.drift.load(window.driftAppId);
    }, 6000);
  }
};
